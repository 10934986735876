import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/main/HomeView";
import partner from "../views/main/PartnerView";
import impressum from "../views/main/ImpressumView";
import about from "../views/dic/AboutView";
import team from "../views/dic/TeamView";
import projects from "../views/dic/ProjectsView";
import services from "../views/services/ServicesView";
import quality from "../views/services/QualityView";
import contact from "../views/main/ContactView";
import dzno from "../views/datausage/DZNO";
import methods from "../views/datausage/MethodsView";
import patientInfo from "../views/datausage/PatientInformation";
import revocation from "../views/datausage/RevocationView";
import requestSubmission from "../views/datausage/RequestSubmission";
import UAC from "../views/datausage/UACView";
import dic from "../views/dic/projects/DIZ";
import mii from "../views/dic/projects/MII";
import num from "../views/dic/projects/NumView";
import miracum from "../views/dic/projects/MiracumView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "DIZ",
    component: home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/partner",
    name: "partner",
    component: partner,
    meta: {
      title: "Partner",
    },
  },
  {
    path: "/impressum",
    name: "impressum",
    component: impressum,
    meta: {
      title: "Impressum",
    },
  },
  {
    path: "/dic/about",
    name: "about",
    component: about,
    meta: {
      title: "About",
    },
  },
  {
    path: "/dic/team",
    name: "team",
    component: team,
    meta: {
      title: "Team",
    },
  },
  {
    path: "/dic/projects",
    name: "projects",
    component: projects,
    meta: {
      title: "Projects",
    },
  },
  {
    path: "/dic/projects/dic",
    name: "dic",
    component: dic,
    meta: {
      title: "Intern",
    },
  },
  {
    path: "/dic/projects/mii",
    name: "mii",
    component: mii,
    meta: {
      title: "Medizin Informatik Initiative",
    },
  },
  {
    path: "/dic/projects/miracum",
    name: "miracum",
    component: miracum,
    meta: {
      title: "MIRACUM",
    },
  },
  {
    path: "/dic/projects/num",
    name: "num",
    component: num,
    meta: {
      title: "NUM",
    },
  },
  {
    path: "/services/ourServices",
    name: "services",
    component: services,
    meta: {
      title: "Unsere Leistungen",
    },
  },
  {
    path: "/services/quality",
    name: "quality",
    component: quality,
    meta: {
      title: "Qualitätsmanagement",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: contact,
    meta: {
      title: "Kontakt",
    },
  },
  {
    path: "/datausage/dzno",
    name: "dzno",
    component: dzno,
    meta: {
      title: "DZNO",
    },
  },
  {
    path: "/datausage/methods",
    name: "methods",
    component: methods,
    meta: {
      title: "Verfahren",
    },
  },
  {
    path: "/datausage/patientInfo",
    name: "patientInfo",
    component: patientInfo,
    meta: {
      title: "Patienten Info",
    },
  },
  {
    path: "/datausage/widerruf",
    name: "revocation",
    component: revocation,
    meta: {
      title: "Revoke Consent",
    },
  },
  {
    path: "/datausage/requestSubmission",
    name: "requestSubmission",
    component: requestSubmission,
    meta: {
      title: "Antragseinreichung",
    },
  },
  {
    path: "/datausage/UAC",
    name: "UAC",
    component: UAC,
    meta: {
      title: "UAC",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `DIZ ${to.meta.title ? `- ${to.meta.title}` : ""}`;
  window.scrollTo(0, 0);
  next();
});

export default router;
