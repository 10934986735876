<template>
  <div class="carousel">
    <carousel-3d
      :autoplay="true"
      :autoplay-hover-pause="true"
      :autoplay-timeout="4000"
      :display="3"
      :width="620"
      :height="400"
    >
      <slide v-for="(slide, i) in slides" :index="i" :key="i" class="slide">
        <v-row :class="`${slide.class} logo`" row wrap>
          <v-col>
            <v-card flat :class="`${slide.name}`">
              <v-img
                :src="require(`../../assets/projects/${slide.src}`)"
                class="mx-auto"
                :width="$vuetify.breakpoint.xs ? '65%' : '100%'"
                height="100%"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-10" v-if="slide.name == 'fdpg' || slide.name == 'biobank'">
          <v-btn
            outlined
            large
            text
            class="mx-auto"
            :href="slide.route"
          >
            {{ $t("projects.learn-more") }}</v-btn
          >
        </v-row>
          <v-row class="mt-10" v-else>
            <v-btn
              outlined
              large
              text
              class="mx-auto"
              :html="{ name: slide.route }"
            >
              {{ $t("projects.learn-more") }}</v-btn
            >
          </v-row>
      </slide>
    </carousel-3d>
  </div>
</template>
<script>
export default {
  data() {
    return {
      slides: [
        {
          name: "diz",
          src: "DIZ_project.png",
          route: "dic",
          class: "card-diz-project",
        },
        {
          name: "miracum",
          src: "miracum_project.png",
          route: "miracum",
          class: "card-miracum-project",
        },
        {
          name: "mii",
          src: "MII_project.png",
          route: "mii",
          class: "card-mii-project",
        },
        {
          name: "num",
          src: "num_project.svg",
          route: "num",
          class: "card-num-project",
        },
        {
          name: "biobank",
          src: "FB11_Biobank_logo.png",
          route: "https://uni-giessen.de/de/fbz/fb11/forschung/FB11Biobank",
          class: "card-external-project",
        },
        {
          name: "fdpg",
          src: "fdpg_logo.png",
          route: "https://forschen-fuer-gesundheit.de/projektregister/",
          class: "card-external-project",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.carousel {
  padding: 5rem 0;
  min-height: 100vh;
  @media #{map-get($display-breakpoints, 'xl-only')} {
    min-height: 62vh;
    padding: 3rem 0;
  }
}

.slide {
  border: 3px ridge #16a436;
  background: white;
  box-shadow: 1px 2px 20px 7px rgba(0, 0, 0, 0.4);
}

.logo {
  height: 75%;
}

.num,
.mii {
  width: 25rem !important;
  height: 15rem !important;
  margin: auto !important;
  background: transparent;
}

.biobank {
  margin: auto !important;
  background: transparent;
  margin-top: 2rem !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 0.8rem !important;
  }
}

.fdpg{
  width: 36rem !important;
  height: 16rem !important;
  margin: auto !important;
  background: transparent;
  margin-top: 2rem !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 0.8rem !important;
  }
}

.mii {
  margin-top: 2rem !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    margin-top: 0.8rem !important;
  }
}

.diz {
  width: 30rem !important;
  height: 16rem !important;
  margin: auto !important;
  padding-top: 3rem;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding-top: 2rem;
  }
  background: transparent;
}

.miracum {
  width: 32rem !important;
  height: 12rem !important;
  margin: auto !important;
  padding-top: 4rem;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding-top: 3rem;
  }
  background: transparent;
}

.carousel-3d-container {
  padding: 2rem 0 !important;
  height: 60% !important;
}

.card-diz-project {
  background: linear-gradient(
    90deg,
    hsla(136, 82%, 72%, 1) 0%,
    hsla(205, 84%, 36%, 1) 100%
  );
}

.card-num-project {
  background: linear-gradient(
    90deg,
    rgba(235, 88, 106, 1) 0%,
    rgba(254, 229, 10, 1) 82%
  );
}
.card-mii-project {
  background: linear-gradient(
    90deg,
    rgba(113, 184, 1, 0.6) 0%,
    rgba(52, 116, 170, 0.6) 82%
  );
}
.card-miracum-project {
  background: linear-gradient(
    90deg,
    hsla(297, 59%, 34%, 1) 0%,
    hsla(345, 74%, 58%, 1) 50%,
    hsla(22, 87%, 56%, 1) 100%
  );
}

.card-external-project {
  background: linear-gradient(
    90deg,
    rgb(228, 158, 7) 0%,
    rgb(109, 180, 174) 100%
  );
}
</style>
